<template>
  <div>
    <v-expansion-panels v-if="mobile" flat class="sectionMobile">
      <v-expansion-panel
          v-for="(section,i) in all"
          :key="i"
      >
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t(section.title)}}</h3>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t(section.text, { route: section.link })"></p>
          <br>
          <v-img
              class="imgSections"
              :src="section.image"
              contain
              height="150"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="center" v-bind:class="mobile ? 'sectionPartMobile' : 'sectionsPart'" no-gutters v-if="!mobile">
      <v-col
          v-bind:cols="mobile ? 12 : 6"
          class="sectionsSecondPart"
          v-for="(section, index) in sections"
          :key="index">
        <v-img
            class="imgSections"
            :src="section.image"
            contain
            height="175"
        />
        <br>
        <h3 class="titleSection"> {{ $t(section.title)}}</h3> <br> <br v-if="section.noJumpLine"> <br v-if="$i18n.locale !== 'fr' && section.title === 'section8'">
        <p class="textSection" v-html="$t(section.text, { route: section.link })"></p>
      </v-col>
      <v-col cols="12" class="sectionsSecondPart">
        <v-img
            class="imgSections"
            :src="lastSection.image"
            contain
            height="175"
        />
        <br>
        <h3 class="titleLastSection"> {{ $t(lastSection.title)}}</h3> <br>
        <p v-bind:class="mobile ? 'textLastSectionMobile' : 'textLastSection'" v-html="$t(lastSection.text, { route: lastSection.link })"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'EHPADSecondPartComp',
    props: ['mobile'],
    data: () => ({
      all: [],
      sections: [
        {
          image: require('@/assets/vitaeImg/section5.png'),
          title: 'section1',
          link: '/activities/adapted-physical-activities',
          text: 'sectionText1',
        },
        {
          image: require('@/assets/vitaeImg/section17.png'),
          title: 'section2',
          link: '/activities/olympics',
          text: "sectionText2",
          noJumpLine: true
        },
        {
          image: require('@/assets/vitaeImg/section14.png'),
          title: 'section3',
          link: '/activities/memory-gym',
          text: 'sectionText3',
          noJumpLine: true
        },
        {
          image: require('@/assets/vitaeImg/section20.png'),
          title: 'section4',
          link: '/activities/physical-activities-away',
          text: 'sectionText4',
        },
        {
          image: require('@/assets/vitaeImg/section18.png'),
          title: 'section5',
          link: '/activities/health-courses',
          text: 'sectionText5',
        },
        {
          image: require('@/assets/vitaeImg/section19.png'),
          title: 'section6',
          link: '/activities/digital-workshop',
          text: "sectionText6",
        },
        {
          image: require('@/assets/vitaeImg/section16.png'),
          title: 'section7',
          link: '/activities/nutrition-workshop',
          text: 'sectionText7',
        },
        {
          image: require('@/assets/vitaeImg/section15.png'),
          title: 'section8',
          link: '/activities/sleep-workshop',
          text: 'sectionText8',
        }
      ],
      lastSection: {
        image: require('@/assets/vitaeImg/section12.png'),
        title: 'lastSection',
        link: '/activities/well-being-workshop',
        text: 'lastSectionText',
      }
    }),
    created() {
      this.$set(this, 'all', this.sections.concat(this.lastSection))
    }
  }
</script>

<i18n>
{
  "en": {
    "section1": "Adapted physical activities",
    "sectionText1": "We offer many adapted physical activities in institutes that adapt to all fitness levels and all tastes: <strong> gentle gym, yoga, Pilate, aquagym, velaqua, fall prevention, active walking, Nordic walking. </strong> <a class='linkText' href='{route}'>SEE MORE</a>",
    "section2": "Olympics",
    "sectionText2": "The Olympics brings together several <strong> fun activities </strong> of skill, throwing and precision. <a class='linkText' href='{route}'>SEE MORE</a>",
    "section3": "The memory gym",
    "sectionText3": "The memory gym: <strong> I learn in motion </strong> is an activity combining movement with cognitive work. <a class='linkText' href='{route}'>SEE MORE</a>",
    "section4": "My physical activity adapted from a distance",
    "sectionText4": "Using a high-performance TV webcam, we offer adapted physical activity sessions and <strong> pre-recorded exercises to stimulate residents on a daily basis! </strong> <a class='linkText' href='{route}'>SEE MORE</a>",
    "section5": "The health courses in my establishment",
    "sectionText5": "Using <strong> 6 digital tablets on a desk offering a specific physical exercise, </strong> residents will be guided from tablet to tablet using visual cues with arrows. <a class='linkText' href='{route}'>SEE MORE</a>",
    "section6": "Digital workshop Aging well with the times",
    "sectionText6": "We offer many adapted physical activities in institutes that adapt to all fitness levels and all tastes: <strong> gentle gym, yoga, Pilate, aquagym, velaqua, fall prevention, active walking, Nordic walking. </strong> <a class='linkText' href='{route}'>SEE MORE</a>",
    "section7": "Nutrition workshop Good on your plate",
    "sectionText7": "<strong> Nutrition plays a very important role in health. </strong> <br> As we age, the body changes, so do nutritional needs. Contrary to popular belief, needs do not decrease with age and some even increase. <a class='linkText' href='{route}'>SEE MORE</a>",
    "section8": "Sleep workshop Sleep well",
    "sectionText8": "<strong> Sleep </strong> is a physiological and fundamental need. A human being spends about a third of his life sleeping. It has a dual function: <strong> it is both a determinant of good health and an indicator of quality of life. </strong> <a class='linkText' href='{route}'>SEE MORE</a>",
    "lastSection": "Well-being workshop Take care of yourself",
    "lastSectionText": "The difference between our aspirations and the successes we achieve builds self-esteem. Discussing, sharing, creating links with others, are moments that maintain good self-esteem. <strong> The objective of this workshop is to strengthen a positive image of oneself </strong> in order to adopt favorable attitudes, particularly for their health and well-being. <a class='linkText' href='{route}'>SEE MORE</a>"
  },
  "fr": {
    "section1": "Les activités physiques adaptées",
    "sectionText1": "Nous proposons de nombreuses activités physiques adaptées en institut qui s’adaptent à tous les niveaux de forme et à toutes les envies : <strong> gym douce, yoga, Pilate, aquagym, velaqua, prévention de la chute, marche active, marche nordique. </strong> <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "section2": "Les olympiades",
    "sectionText2": "Les olympiades regroupent plusieurs <strong> activités ludiques </strong> d’adresse, de lancer et de précision. <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "section3": "La gym mémoire",
    "sectionText3": "La gym mémoire : <strong> j’apprends en mouvement </strong> est une activité associant le mouvement à un travail cognitif. <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "section4": "Mon activité physique adaptée à distance",
    "sectionText4": "A l’aide d’une webcam TV haute performance, nous proposons des séances d’activité physique adaptée et <strong> des exercices pré-enregistrés pour stimuler quotidiennement les résidents ! </strong> <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "section5": "Les parcours santé dans mon établissement",
    "sectionText5": "A l’aide de <strong> 6 tablettes numériques sur un pupitre proposant un exercice physique particulier, </strong> les résidents seront guidés de tablette en tablette à l’aide de repères visuels fléchés. <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "section6": "Atelier numérique Bien vieillir avec son temps",
    "sectionText6": "Nous proposons de nombreuses activités physique adaptée en institut qui s’adapte à tous les niveaux de forme et à toutes les envies : <strong> gym douce, yoga, Pilate, aquagym, velaqua, prévention de la chute, marche active, marche nordique. </strong> <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "section7": "Atelier nutrition Bien dans son assiette",
    "sectionText7": "<strong> La nutrition joue un rôle très important sur la santé. </strong> <br> En prenant de l’âge, l’organisme change, les besoins nutritionnels aussi. Contrairement aux idées reçues, les besoins ne diminuent pas avec l’âge et certains augmentent même. <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "section8": "Atelier sommeil Bien dormir",
    "sectionText8": "<strong> Le sommeil </strong> est un besoin physiologique et fondamental. Un être humain passe environ le tiers de sa vie à dormir. Il a une double fonction : <strong> il est à la fois un déterminant d’une bonne santé et un indicateur de qualité de vie. </strong> <a class='linkText' href='{route}'>VOIR PLUS</a>",
    "lastSection": "Atelier bien être Prendre soin de soi",
    "lastSectionText": "La différence entre nos aspirations et les succès que nous parvenons à accomplir construit l’estime de soi. Echanger, partager, créer du lien avec les autres, sont des moments qui entretiennent une bonne estime de soi. <strong> Cet atelier a pour objectif de renforcer une image positive de soi-même </strong> afin d’adopter des attitudes favorables notamment pour sa santé et son bien-être. <a class='linkText' href='{route}'>VOIR PLUS</a>"
  }
}
</i18n>

<style scoped>

.sectionsPart {
  padding: 100px 150px 50px;
}

.sectionMobile {
  padding-top: 50px;
  padding-bottom: 50px;
}
.textSectionMobile {
  font-size: 14px;
  text-align: left;
  color: #808080;
}
.titleSectionMobile {
  margin-right: 100px;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.sectionsSecondPart {
  text-align: center;
  padding-bottom: 20px;
}
@media screen and (min-width: 1500px){
  .titleSection {
    font-family: Comfortaa, sans-serif;
    color: #57939E;
    padding-right: 250px;
    padding-left: 250px;
  }
  .titleLastSection {
    font-family: Comfortaa, sans-serif;
    color: #57939E;
  }
  .textLastSection {
    text-align: left;
    color: #808080;
    padding-right: 450px;
    padding-left: 450px;
  }
}

@media screen and (max-width: 1500px){
  .titleSection {
    font-family: Comfortaa, sans-serif;
    color: #57939E;
    padding-right: 150px;
    padding-left: 150px;
  }
  .titleLastSection {
    font-family: Comfortaa, sans-serif;
    color: #57939E;
  }
  .textLastSection {
    text-align: left;
    color: #808080;
    padding-right: 200px;
    padding-left: 200px;
  }
}

.textLastSectionMobile {
  text-align: left;
  color: #808080;
  padding-right: 40px;
  padding-left: 40px;
}

.textSection {
  text-align: left;
  color: #808080;
  padding-right: 50px;
  padding-left: 50px;
}

.textSectionMobile >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

.textSection >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

.textLastSection >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

</style>
