<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'" no-gutters>
      <v-col cols="1"></v-col>
      <v-col v-bind:cols="mobile ? 10 :5" v-bind:class="mobile ? 'textFirstSectionMobile' : 'textFirstSection'">
        <p v-html="$t('firstSectionText1')"></p>
        <v-row v-if="mobile" justify="center">
          <v-col cols="6">
            <p v-html="$t('firstSectionText2')"></p>
          </v-col>
          <v-col cols="6">
            <v-img
                :src="require('@/assets/picto/pictoEphad.svg')"
                contain
                height="200"
            />
          </v-col>
        </v-row>
        <p v-html="$t('firstSectionText2')" v-if="!mobile"></p>
        <p v-html="$t('firstSectionText3')"></p>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4" v-if="!mobile">
        <v-img
            class="imgFirstSection"
            :src="require('@/assets/picto/pictoEphad.svg')"
            contain
            height="300"
        />
      </v-col>
    </v-row>
    <v-row v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
      <v-col cols="1"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 2" class="textLeftSecondSection">
        <p v-html="$t('secondSectionText1')"></p>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 8" v-bind:class="mobile ? 'textRightSecondSectionMobile' : 'textRightSecondSection'">
        <p v-html="$t('secondSectionText2')"></p>
      </v-col>
      <v-col cols="12">
        <v-icon
            class="iconEndSecondSection"
            size="100"
            color="#FFFFFF"
        >
          mdi-chevron-down
        </v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'EHPADFirstPartComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Ehpad and autonomous residence",
    "firstSectionText1": "Preserving the physical and moral health of residents is undoubtedly a priority for the teams of establishments caring for the elderly. Thus the association <strong> Vitaé sport santé offers a large number of physical activities </strong> specially adapted for the elderly in institutes: <strong> Soft gym, Yoga, Pilate, Aquagym, Balance, Fall prevention. </strong>",
    "firstSectionText2": "These activities are provided by experienced health sport professionals, who support the establishments and their residents towards improving their autonomy as well as their quality of life.",
    "firstSectionText3": "In the same way, the association also offers activities around nutrition, sleep, digital technology and self-esteem in partnership with qualified professionals.",
    "secondSectionText1": "The benefits of physical activity in a salon",
    "secondSectionText2": "The benefits of physical activities in institutes are well established, particularly in terms of social ties and the dynamics they provide to residents. In addition to being one of the <strong> best tools in the prevention of chronic diseases </strong>, physical activity will have a rapid beneficial impact on many aspects of your residents."
  },
  "fr": {
    "mainTitle": "Ehpad et résidence autonome",
    "firstSectionText1": "Préserver la santé physique et morale des résidents est sans conteste une priorité pour les équipes des établissements accueillants des personnes âgées. Ainsi l’association <strong> Vitaé sport santé propose un grand nombre d’activités physiques </strong> spécialement adaptées pour les personnes âgées en institut : <strong> Gym douce, Yoga, Pilate, Aquagym, Equilibre, Prévention de la chute. </strong>",
    "firstSectionText2": "Ces activités sont dispensées par des professionnels du sport santé expérimentés, qui accompagnent les établissements et leurs résidents vers une amélioration de leur autonomie ainsi que de leur qualité de vie.",
    "firstSectionText3": "Dans la même veine l’association propose également des activités autour de la nutrition, du sommeil, du numérique et de l’estime de soi en partenariat avec des professionnels diplômés.",
    "secondSectionText1": "Les bienfaits de l'activité physique en institut",
    "secondSectionText2": "Les bienfaits des activités physiques en institut ne sont plus à prouver, notamment sur le plan du lien social et de la dynamique qu’elles procurent aux résidents. En plus d’être un des <strong> meilleurs outils dans la prévention des maladies chroniques </strong>, l’activité physique va avoir un impact bénéfique rapide sur plusieurs aspects de vos résidents."
  }
}
</i18n>

<style scoped>

.firstSection {
  padding-bottom: 35px;
}

.firstSectionMobile {
  font-size: 13px;
}

.secondSectionMobile {
  text-align: center;
  margin-top: 50px;
  padding-top: 50px;
  color: #1579A7;
  background-color: #D5EEF4;
  font-size: 13px;
}

.textFirstSectionMobile {
  color: #808080;
}

.textFirstSection {
  color: #808080;
  padding-left: 250px;
  padding-right: 100px;
}

.imgFirstSection {
  margin-top: 20px;
  margin-right: 150px;
}

.secondSection {
  text-align: center;
  margin-top: 50px;
  padding-top: 100px;
  color: #1579A7;
  background-color: #D5EEF4;
}

.textLeftSecondSection {
  text-align: left;
  padding-top: 40px;
  padding-left: 255px;
  padding-right: 100px;
  font-size: 20px;
}

.textRightSecondSectionMobile {
  text-align: left;
  margin-left: 35px;
}

.textRightSecondSection {
  text-align: left;
  margin-left: 60px;
}

.iconEndSecondSection {
  margin-top: -20px;
  text-align: center;
}

</style>
