<template>
  <div>
    <EHPADFirstPartComp v-bind:mobile="mobile"/>
    <CommonEstablishmentsComp v-bind:mobile="mobile"/>
    <EHPADSecondPartComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import EHPADFirstPartComp from '@/components/desktop/Establishments/EHPADFirstPartComp'
import CommonEstablishmentsComp from '@/components/desktop/Establishments/CommonEstablishmentsComp.vue'
import EHPADSecondPartComp from '@/components/desktop/Establishments/EHPADSecondPartComp.vue'

  export default {
    props: ['mobile'],
    name: 'EPHAD',
    components: {
      EHPADFirstPartComp,
      CommonEstablishmentsComp,
      EHPADSecondPartComp
    },
  }
</script>
