<template>
  <div>
    <v-row justify="center" class="sectionCommon" no-gutters>
      <v-col cols="4" class="leftSectionCommon" v-if="!mobile">
        <h3 class="subtitle">{{ $t('subTitle1') }}</h3> <br>
        <div class="textSectionLeft">
          {{ $t('text1') }}
        </div>
        <v-img
            class="imgLeft1"
            :src="require('@/assets/picto/pictoEstablishementsCommon3.svg')"
            contain
            height="125"
        />
        <h3 class="subtitle">{{ $t('subTitle2') }}</h3> <br>
        <div class="textSectionLeft">
          {{ $t('text2') }}
        </div>
        <v-img
            class="imgLeft2"
            :src="require('@/assets/picto/pictoEstablishementsCommon2.svg')"
            contain
            height="125"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 1 : 2">
        <v-img
            :src="require('@/assets/picto/separator5.svg')"
            contain
            v-bind:height="mobile ? 1200 : 650"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 9 : 4" class="rightSectionCommon">
        <v-img
            v-bind:class="mobile ? 'imgMobile' : 'imgRight1'"
            :src="require('@/assets/picto/pictoEstablishementsCommon1.svg')"
            contain
            v-bind:style="mobile ? 'margin-top: -5px' : ''"
            height="125"
        />
        <h3 v-if="mobile" v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'">{{ $t('subTitle1') }}</h3> <br>
        <div v-if="mobile" v-bind:class="mobile ? 'textMobile' : 'textSectionLeft'">
          {{ $t('text1') }}
        </div>
        <v-img
            v-if="mobile"
            class="imgMobile"
            :src="require('@/assets/picto/pictoEstablishementsCommon3.svg')"
            contain
            height="125"
        />
        <h3 v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'">{{ $t('subTitle3') }}</h3> <br>
        <div v-bind:class="mobile ? 'textMobile' : 'textSectionRight'">
          {{ $t('text3') }}
        </div>
        <v-img
            v-bind:class="mobile ? 'imgMobile' : 'imgRight2'"
            :src="require('@/assets/picto/pictoEstablishementsCommon4.svg')"
            contain
            height="125"
        />
        <h3 v-if="mobile" v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'">{{ $t('subTitle3') }}</h3> <br>
        <div v-if="mobile" v-bind:class="mobile ? 'textMobile' : 'textSectionRight'">
          {{ $t('text3') }}
        </div>
        <v-img
            v-if="mobile"
            class="imgMobile"
            :src="require('@/assets/picto/pictoEstablishementsCommon2.svg')"
            contain
            height="125"
        />
        <h3 v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'">{{ $t('subTitle4') }}</h3> <br>
        <div v-bind:class="mobile ? 'textMobile' : 'textSectionRight'">
          {{ $t('text4') }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'CommonEstablishmentsComp',
      props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "subTitle1": "Cognitive aspect",
    "text1": "Attention, understanding of instructions, memory, ability to make a choice, orientation in time and space",
    "subTitle2": "Physical appearance",
    "text2": "Improves fitness, skill, coordination, balance, endurance and strength",
    "subTitle3": "Social aspect",
    "text3": "Promotes socialization, integration, cooperation, sharing and fight against isolation",
    "subTitle4": "Psychological aspect",
    "text4": "Improve self-esteem, perceived physical worth, sense of competence, reduce stress and anxiety and promote motivation"
  },
  "fr": {
    "subTitle1": "Aspect cognitif",
    "text1": "Améliore l’attention, la compréhension des consignes, la mémoire, l'aptitude à faire un choix et l'orientation dans le temps et l’espace",
    "subTitle2": "Aspect physique",
    "text2": "Améliore la condition physique, l’adresse, la coordination, l’équilibre, l’endurance et la force",
    "subTitle3": "Aspect social",
    "text3": "Favorise la socialisation, l’insertion, la coopération, le partage et lutte contre l’isolement",
    "subTitle4": "Aspect psychologique",
    "text4": "Améliore l’estime de soi, la valeur physique perçue, son sentiment de compétence, réduit le stress et l’anxiété et favorise la motivation"
  }
}
</i18n>

<style scoped>

.sectionCommon {
  color: #FFFFFF;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #1579A7;
}

.textMobile {
  margin-left: 5px;
  font-size: 14px;
}

.subtitleMobile {
  margin-left: 5px;
  font-size: 16px;
}

.imgMobile {
  margin-right: 100px;
  margin-bottom: 50px;
  margin-top: 70px;
}

.leftSectionCommon {
  padding-top: 10px;
  text-align: right;
}

.imgLeft1 {
  margin-top: 100px;
  margin-bottom: 90px;
  margin-left: 400px;
}


.imgLeft2 {
  margin-top: 110px;
  margin-left: 400px;
}

.rightSectionCommon {
  padding-top: 20px;
  padding-right: 250px;
  text-align: left;
}

.imgRight1 {
  margin-bottom: 80px;
  margin-right: 400px;
}

.imgRight2 {
  margin-top: 100px;
  margin-bottom: 90px;
  margin-right: 400px;
}

</style>
